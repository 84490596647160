export async function loadHash(message: string, abortSignal: AbortSignal): Promise<string | null> {
  try {
    const hashResponse = await fetch('/api/_memoizedHash/', {
      method: 'POST',
      body: JSON.stringify({ message }),
      signal: abortSignal,
    });
    if (!hashResponse.ok) {
      return null;
    }
    const hashResponseBody: { hash: string } = await hashResponse.json();
    return hashResponseBody.hash;
  } catch (error) {
    if (isAbortError(error)) return null; // if the query has been aborted, do nothing
    throw error;
  }
}

function isAbortError(error: unknown) {
  return (!!error && typeof error === 'object' && 'name' in error && error.name === 'AbortError') || error === 'abort';
}
