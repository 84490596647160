import cx from 'classnames';

import type { StoryHeaderData } from '@hubcms/domain-story';
import { StoryHeadline } from './StoryHeadline';
import { StoryLabels } from './StoryLabels';

import styles from './story-title.module.scss';

type StoryTitleProps = Pick<StoryHeaderData, 'articleId' | 'headingData' | 'label' | 'sublabel'> & {
  className?: string;
  isPremium?: boolean;
};

export function StoryTitle({ headingData, label = '', sublabel = '', articleId, className, isPremium = false }: StoryTitleProps) {
  return (
    <div className={cx(styles.storyTitle, className)}>
      <StoryLabels label={label} sublabel={sublabel} isPremium={isPremium} />
      {headingData?.text && <StoryHeadline articleId={articleId} {...headingData} isPremium={isPremium} />}
    </div>
  );
}
