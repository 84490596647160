import type { AdSectionParams } from '@hubcms/domain-article';

type AdConfigBase = {
  adFormatsInBody: string[];
  adMinElemsAfterInBody: number;
  adMinTextLengthInBody: number;
  adPositionsInBody: number[];
};

type AdConfig = AdConfigBase &
  (
    | {
        hasAdBeforeBody: true;
        adFormatBeforeBody: string;
      }
    | {
        hasAdBeforeBody: false;
        adFormatBeforeBody?: never;
      }
  );

/**
 * All ad positions determine AFTER which element the ad should be placed, considering whether the element type is suitable for an ad.
 * However, the 0 position simply places the ad BEFORE all elements, regardless of the type of element that follows it.
 *
 * @example
 * [0, 3, 6, 9] // BEFORE all elements, AFTER the 3rd viable element, AFTER the 6th viable element, etc.
 *
 * So, if the first ad is placed before the body, we:
 * - remove the first index from the ad positions array
 * - take the first in-body ad format and use it as the format for the ad preceding the body
 * - move the first in-body ad format to the end of the array (since it's already used for the ad before the body, and we don't want to repeat, but if the number of ad formats is smaller than the number of positions, we loop through it, see example below)
 *
 * Furthermore, we adjust the ad positions array by subtracting 1 from each element.
 * The elements are 0-based, but the ad positions are 1-based.
 * If we don't match the index of the ad positions to the elements, the ad will be placed after the wrong element.
 * If the ad positions are [3, 6, 9, 12], the ads should be placed after the 3rd, 6th, 9th, and 12th element.
 * Without adjusting the index of the positions the ads will be placed AFTER the 4th (index 3), 7th (index 6), 10th (index 9), and 13th (index 12) element.
 *
 * @example
 * {
 *  adPostions: [0, 3, 6, 9, 12],
 *  adFormatsInBody: ['format1', 'format2', 'format3'],
 * }
 * 👇
 * {
 *  adFormatBeforeBody: 'format1',
 *  adFormatsInBody: ['format2', 'format3', 'format1'],
 *  adPositionsInBody: [2, 5, 8, 11],
 * }
 */
export function getAdConfigFromAdSectionParams(
  adSectionParams: Pick<AdSectionParams, 'adFormatsInBody' | 'adMinElemsAfter' | 'adMinTextLength' | 'adPositions'>,
): AdConfig {
  const hasAdFormats = adSectionParams.adFormatsInBody.length > 0;
  const hasAdPositionBeforeBody = adSectionParams.adPositions[0] === 0;

  if (hasAdFormats && hasAdPositionBeforeBody) {
    return {
      hasAdBeforeBody: true,
      adFormatBeforeBody: adSectionParams.adFormatsInBody[0],
      adFormatsInBody: adSectionParams.adFormatsInBody.slice(1).concat(adSectionParams.adFormatsInBody[0]),
      adMinElemsAfterInBody: adSectionParams.adMinElemsAfter,
      adMinTextLengthInBody: adSectionParams.adMinTextLength,
      adPositionsInBody: adSectionParams.adPositions.slice(1).map(position => position - 1),
    };
  }

  return {
    hasAdBeforeBody: false,
    adFormatsInBody: adSectionParams.adFormatsInBody,
    adMinElemsAfterInBody: adSectionParams.adMinElemsAfter,
    adMinTextLengthInBody: adSectionParams.adMinTextLength,
    adPositionsInBody: adSectionParams.adPositions.slice(hasAdPositionBeforeBody ? 1 : 0).map(position => position - 1),
  };
}
