import type { StoryElement, StoryElementType } from '@hubcms/domain-story-elements';

const VIABLE_ELEMENT_TYPES: StoryElementType[] = [
  'paragraph',
  'infoblock_paragraph',
  'interview',
  'infoblock_group',
  'list_bulleted',
  'list_numbered',
  'infoblock_list_bulleted',
  'infoblock_list_numbered',
];

const FORCE_CLEARING_TYPES: StoryElementType[] = ['infoblock_group'];

export function canAlignNextToFloatingElement(storyElement: StoryElement | undefined, canClear?: boolean): boolean {
  return (
    !!storyElement &&
    VIABLE_ELEMENT_TYPES.includes(storyElement.type) &&
    (!canClear || !FORCE_CLEARING_TYPES.includes(storyElement.type))
  );
}
