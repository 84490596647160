import { type InterviewStoryElement, isInterviewElement } from '@hubcms/domain-story-elements';
import type { PreparedElement } from '../domain/prepared-element';

export function splitElement(preparedElement: PreparedElement, hasAds?: boolean): PreparedElement[] {
  if (!hasAds) {
    return [preparedElement];
  }

  const storyElement = preparedElement.storyElement;
  if (isInterviewElement(storyElement) && storyElement.data.items.length > 2) {
    const nrOfElements = Math.ceil(storyElement.data.items.length / 2);

    return Array.from({ length: nrOfElements }, (_, idx) => {
      const interviewElementSplit: InterviewStoryElement = {
        ...storyElement,
        data: {
          ...storyElement.data,
          items: storyElement.data.items.slice(idx * 2, idx * 2 + 2),
        },
        id: `${storyElement.id}-split-${idx}`,
      };
      return {
        ...preparedElement,
        storyElement: interviewElementSplit,
      };
    });
  }

  return [preparedElement];
}
