import { Fragment } from 'react';
import { ElementsRenderer } from '@hubcms/data-access-story-elements';
import { InlineAd } from '@hubcms/feature-ads';

import type { ELEMENT_COMPONENT_MAP } from '../../domain/element-component-map';
import { elementsReducer, INITIAL_CONCATENATION } from '../../utils/elementsReducer';
import { isStoryElementTypeWithComponent } from '../../utils/getComponent';
import { insertExtraElements } from '../../utils/insertExtraElements';
import { prepareElement } from '../../utils/prepareElement';
import { splitElement } from '../../utils/splitElement';
import getAdFormatForStoryElement from '../../utils/getAdFormatForStoryElement';
import { isElementViableForAd } from '../../utils/isElementViableForAd';
import { getAdConfigFromAdSectionParams } from '../../utils/getAdConfigFromAdSectionParams';
import type { ElementProps } from './types';

export default function Elements({
  elements,
  children: renderElementFn = child => child,
  imageClassName = 'fullWidthSm',
  hasAds = false,
  adSectionParams,
}: ElementProps<keyof typeof ELEMENT_COMPONENT_MAP>) {
  const preparedElements = elements
    .filter(({ type }) => isStoryElementTypeWithComponent(type))
    .map(prepareElement(imageClassName))
    .flatMap(insertExtraElements)
    .flatMap(preparedElement => splitElement(preparedElement, hasAds));

  const adConfig = adSectionParams ? getAdConfigFromAdSectionParams(adSectionParams) : undefined;

  const adViableElements = preparedElements.filter(preparedElement =>
    isElementViableForAd(preparedElement, adConfig?.adMinTextLengthInBody ?? 0),
  );

  const getAdOptions =
    hasAds && !!adConfig
      ? (id: string) =>
          getAdFormatForStoryElement(
            id,
            adViableElements,
            adConfig.adPositionsInBody,
            adConfig.adMinElemsAfterInBody,
            adConfig.adFormatsInBody,
          )
      : () => null;
  const adParams = {
    getAdOptions,
    AdElement: InlineAd,
  };

  const concatenatedElements = preparedElements.reduce(
    elementsReducer(renderElementFn, adParams),
    INITIAL_CONCATENATION,
  ).elements;

  return (
    <>
      {adConfig?.hasAdBeforeBody && <InlineAd adFormat={adConfig.adFormatBeforeBody} />}
      {concatenatedElements.map(({ key, jsx }) => (
        <Fragment key={key}>{jsx}</Fragment>
      ))}
    </>
  );
}

ElementsRenderer.renderer = Elements;
