import { type MutableRefObject, useEffect } from 'react';

type UsePassThroughProps = {
  articleId?: string;
  headlineRef: MutableRefObject<HTMLElement>;
};

export const usePassThrough = ({ articleId, headlineRef }: UsePassThroughProps) => {
  useEffect(() => {
    const currentHeadline = headlineRef.current;
    const doubleClickHandler = (mouseEvent: MouseEvent) => {
      if (articleId && mouseEvent.altKey && mouseEvent.shiftKey) {
        mouseEvent.stopPropagation();
        mouseEvent.preventDefault();
        globalThis.open(`/api/_cue/${articleId}`, '_blank');
      }
    };
    currentHeadline?.addEventListener('dblclick', doubleClickHandler);

    return () => {
      currentHeadline?.removeEventListener('dblclick', doubleClickHandler);
    };
  }, [headlineRef, articleId]);
};
