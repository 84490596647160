import type { StoryElementType } from '@hubcms/domain-story-elements';
import { getElementAlignment } from '@hubcms/utils-story-elements';
import { storyElementsToTakeIntoAccountForAds } from '@hubcms/brand';

import type { PreparedElement } from '../domain/prepared-element';

const TEXTUAL_ELEMENTS: StoryElementType[] = ['paragraph', 'sub_head', 'interview'];

function shouldSkipAdPlacement(storyElement: PreparedElement['storyElement']) {
  return (
    (storyElementsToTakeIntoAccountForAds.length > 0 && !storyElementsToTakeIntoAccountForAds.includes(storyElement.type)) ||
    getElementAlignment(storyElement)
  );
}

export function isElementViableForAd(preparedElement: PreparedElement, minTextLength: number) {
  if (preparedElement.isAriaHidden) {
    return false;
  }

  const storyElement = preparedElement.storyElement;

  if (shouldSkipAdPlacement(storyElement)) {
    return false;
  }

  if (!TEXTUAL_ELEMENTS.includes(storyElement.type)) {
    return true;
  }
  const elementLength = storyElement.charCount || 0;
  return elementLength > minTextLength;
}
