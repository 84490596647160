import { Heading, Logo } from '@mediahuis/chameleon-react';
import { type MutableRefObject, useRef } from 'react';
import cx from 'classnames';
import { articleLayoutConfig } from '@hubcms/brand';
import { annotate } from '@hubcms/utils-story-elements';

import { usePassThrough } from '../../../../hooks/usePassThrough';

import styles from './story-headline.module.scss';
import type { StoryHeadlineProps } from './types';

function StoryHeadline({ className, articleId, text, annotations, isPremium = false, level = 1, id, size }: StoryHeadlineProps) {
  const headlineRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;
  usePassThrough({ articleId, headlineRef });

  return (
    <div className={styles.storyHeadline}>
      <Heading
        data-testid="article-headline"
        className={cx(className, styles.storyHeadlineHeading)}
        color="var(--x-story-headline-color, var(--story-headline-color, inherit))"
        level={level}
        id={id}
        size={size}
        ref={headlineRef}
      >
        {articleLayoutConfig.heroblockPremiumIconLocation === 'heading' && isPremium && (
          <Logo name="logo-premium-main" className={cx(styles.premiumIcon)} />
        )}
        {annotate(text, annotations)}
      </Heading>
    </div>
  );
}

export default StoryHeadline;
