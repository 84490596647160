import { LinkText } from '@mediahuis/chameleon-react';
import type { FC, PropsWithChildren, ReactElement } from 'react';
import type { EnrichedAnnotation } from '@hubcms/domain-story-elements';

import styles from './annotation-link.module.scss';

type AnnotationLinkProps = PropsWithChildren<{
  annotation: EnrichedAnnotation;
}>;

const AnnotationLink: FC<AnnotationLinkProps> = ({ annotation, children }) => {
  const { linkProps } = annotation;

  if (!linkProps) {
    return (children as ReactElement) ?? null;
  }

  return (
    <LinkText
      href={linkProps.uri}
      target={linkProps.target}
      rel={linkProps.noFollow ? 'noFollow' : undefined}
      className={styles.annotationLink}
    >
      {children}
    </LinkText>
  );
};

export default AnnotationLink;
