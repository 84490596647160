import { articleLayoutConfig } from '@hubcms/brand';

import { Labels } from '@hubcms/ui-taxonomy';
import type { StoryLabelsProps } from './types';

function StoryLabels({ label, sublabel, isPremium, ...labelProps }: StoryLabelsProps) {
  return (
    <Labels
      label={label}
      sublabel={sublabel}
      isPremium={isPremium ?? false}
      showPremiumIcon={articleLayoutConfig.heroblockPremiumIconLocation === 'label'}
      data-testid="article-label"
      {...labelProps}
    />
  );
}

export default StoryLabels;
